import { css } from '@emotion/react';
import {
  type Button as BaseButton,
  type TextButton as BaseTextButton,
  baseButtonStyle,
} from 'folio-common-components';
import * as React from 'react';

const primaryButtonStyles = css`
  border: 1px solid transparent;
  background: var(--tt-blue-100);
  color: #fff;
  padding: 8px 16px;
  border-radius: var(--border-radius);
  display: inline-block;
  transition: 0.1s;

  :disabled {
    opacity: 0.25;
    cursor: default;
  }

  :not(:disabled):hover {
    background: var(--tt-blue-120);
  }

  :not(:disabled):focus {
    border-color: var(--tt-blue-120);
  }

  :not(:disabled):active {
  }
`;

const secondaryButtonStyles = css`
  background: var(--tt-blue-10);
  color: var(--tt-blue-100);
  padding: 8px 16px;
  border-radius: var(--border-radius);
  display: inline-block;
  transition: 0.2s background, 0.2s box-shadow, border-color 0.2s;

  :disabled {
    opacity: 0.25;
    cursor: default;
  }

  :not(:disabled):hover {
    background: var(--tt-blue-20);
  }

  :not(:disabled):focus {
  }

  :not(:disabled):active {
  }
`;

export const Button: React.FC<React.ComponentProps<typeof BaseButton>> = ({
  level = 'primary',
  size,
  fullWidth,
  ...rest
}) => {
  return (
    <button
      type="button"
      css={[
        baseButtonStyle,
        level === 'primary' ? primaryButtonStyles : secondaryButtonStyles,
        size === 'large'
          ? css`
              padding: 12px 32px;
            `
          : null,
        fullWidth
          ? css`
              width: 100%;
            `
          : null,
      ]}
      {...rest}
    />
  );
};

export const DummyButton: React.FC<React.ComponentProps<typeof BaseButton>> = ({
  level = 'primary',
  size,
  fullWidth,
  ...rest
}) => {
  return (
    <span
      css={[
        baseButtonStyle,
        level === 'primary' ? primaryButtonStyles : secondaryButtonStyles,
        size === 'large'
          ? css`
              padding: 12px 32px;
            `
          : null,
        fullWidth
          ? css`
              width: 100%;
            `
          : null,
      ]}
      {...rest}
    />
  );
};

export const TextButton: React.FC<
  React.ComponentProps<typeof BaseTextButton>
> = props => {
  return (
    <button
      type="button"
      css={[
        baseButtonStyle,
        css`
          color: var(--tt-blue-100);
        `,
      ]}
      {...props}
    />
  );
};
