import styled from '@emotion/styled';
import { space } from 'folio-common-components';

export const Section = styled.div`
  & + & {
    ${space([48], 'margin-top')};
  }
`;

Section.defaultProps = {
  // @ts-expect-error testid not in the typings
  'data-testid': 'section',
};

// FIXME: rename the folder
export const PageCard = styled.div`
  ${space([48], 'margin-bottom')};
`;
