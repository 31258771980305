import styled from '@emotion/styled';
import { fonts, space } from 'folio-common-components';

export const SectionHeading = styled.h3`
  ${fonts.font600book};
  margin: 0;
  ${space([16], 'margin-bottom')};
  margin-left: -0.04em; /* Optical alignment */
  text-wrap: balance;
`;
