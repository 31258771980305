import { Global, css } from '@emotion/react';
import { fonts } from 'folio-common-components';
import * as React from 'react';
/* eslint-disable import/no-internal-modules, @typescript-eslint/ban-ts-comment */
// @ts-expect-error
import rubikMedium from './fonts/rubik-v20-latin-500.woff2';
// @ts-expect-error
import rubikBold from './fonts/rubik-v20-latin-700.woff2';
// @ts-expect-error
import rubikRegular from './fonts/rubik-v20-latin-regular.woff2';
/* eslint-enable */

export const GlobalStyles: React.FC<{
  styles?: ReturnType<typeof css>;
}> = ({ styles }) => (
  <Global
    styles={[
      css`
        @font-face {
          font-family: 'Rubik';
          src: url('${rubikRegular}') format('woff2');
          font-display: swap;
        }

        @font-face {
          font-family: 'Rubik';
          src: url('${rubikMedium}') format('woff2');
          font-weight: 500;
          font-display: swap;
        }

        @font-face {
          font-family: 'Rubik';
          src: url('${rubikBold}') format('woff2');
          font-weight: 700;
          font-display: swap;
        }

        @font-face {
          font-family: fallback;
          src: local(Arial);
          ascent-override: 99.2%;
          descent-override: 33.6%;
        }

        *,
        ::before,
        ::after {
          box-sizing: border-box;
        }

        html,
        body {
          height: 100%;
        }

        html {
          font-family: 'Rubik', fallback, sans-serif, 'Apple Color Emoji',
            'Segoe UI Emoji';
          ${fonts.font200book};
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-feature-settings: 'kern', 'liga';

          --tt-background: #f8f8fc;

          --tt-blue-10: #e6ebff;
          --tt-blue-20: #ced9fe;
          --tt-blue-100: #0a41fa;
          --tt-blue-120: #0834c7;

          --tt-grey-20: #d5d7db;
          --tt-grey-40: #abafb7;
          --tt-grey-60: #818794;
          --tt-grey-80: #51596a;
          --tt-grey-100: #2e384d;
          --tt-grey-120: #0f131a;

          --tt-green-positive: #1ab96d;

          --text-color: var(--tt-grey-100);
          --link-color: var(--tt-blue-120);

          --box-bg-color: var(--tt-blue-10);

          --border-radius: 4px;

          --autosuggest-border-radius: 4px;
          --autosuggest-border-width: 2px;
          --autosuggest-highlight-color: var(--tt-blue-10);
          --autosuggest-border-color: var(--tt-blue-100);
        }

        body {
          margin: 0;
          background: var(--tt-background);
          color: var(--text-color);
          /* Avoid page jumping when navigating, depending on the height of the content */
          overflow-y: scroll;
          -webkit-text-size-adjust: 100%;
          touch-action: manipulation;
        }

        a {
          color: var(--link-color);
          border-radius: var(--border-radius);
        }

        a:hover {
          text-decoration: none;
        }

        b,
        strong {
          font-weight: 500;
        }

        p {
          margin: 1em 0;
        }

        label {
          -webkit-tap-highlight-color: transparent;
        }

        .js-focus-visible *:focus:not(.focus-visible) {
          outline: none;
        }

        .js-focus-visible .focus-visible {
          outline: none;
          box-shadow: 0 0 0 2px var(--tt-blue-100);
        }

        [hidden] {
          display: none !important;
        }

        /* Component overrides */

        input,
        textarea,
        button,
        select {
          border-radius: var(--border-radius) !important;
        }

        :is([type='text'], [type='email'], [type='tel'], textarea, select):not(
            [aria-invalid='true']
          ) {
          border-color: var(--tt-grey-60) !important;

          @media (prefers-contrast: more) {
            border-color: var(--tt-grey-100) !important;
          }
        }

        :is([type='text'], [type='email'], [type='tel'], textarea, select):not(
            [aria-invalid='true']
          ):focus {
          border-color: var(--tt-blue-100) !important;
          box-shadow: inset 0 0 0 1px var(--tt-blue-100) !important;
        }

        :checked + [data-graphics] [data-graphics-background] {
          fill: var(--tt-blue-100) !important;
          stroke: var(--tt-blue-100) !important;
        }

        .focus-visible + [data-graphics] [data-graphics-focus] {
          stroke: var(--tt-blue-100) !important;
        }
      `,
      styles,
    ]}
  />
);
