import styled from '@emotion/styled';
import * as React from 'react';
import { TripletexLogo } from '../../logos';
import { pageMap } from '../../paths';
import { Page } from '../Page';
import { HeaderBar, StyledLink } from './shared';
import type { Props } from './index';

const Wrapper = styled.div`
  padding: 2rem;
  display: grid;
  grid-template-columns: auto 1fr;
`;

const Heading = styled.h1`
  margin: 0;
`;

const StyledLogo = styled(TripletexLogo)`
  display: block;
  margin-right: 16px;
`;

export const LargeHeaderBar: React.FC<Props> = ({ className }) => (
  <HeaderBar className={className}>
    <Page>
      <Wrapper>
        <Heading>
          <StyledLink
            to={{ pathname: pageMap.home, search: window.location.search }}
          >
            <StyledLogo />
          </StyledLink>
        </Heading>
      </Wrapper>
    </Page>
  </HeaderBar>
);
