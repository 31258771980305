import { css } from '@emotion/react';
import { space } from 'folio-common-components';

export function TopText() {
  return (
    <div
      css={css`
        color: var(--tt-grey-80);
        ${space([16], 'margin-bottom')};
        margin-top: 10px; /* Align with menu */
      `}
    >
      Registrering av ditt nye AS
    </div>
  );
}
