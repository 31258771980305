import type { CompanyType, MaBankSelection } from '../gqltypes';
import type { MaSignerURL } from '../state/founding/types';

export function makeId(prefix = ''): string {
  let word = '';
  const charset =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

  while (word.length < 20) {
    const randomValues = window.crypto.getRandomValues(new Uint8Array(1));
    const char = String.fromCharCode(randomValues[0]);
    if (charset.includes(char)) {
      word += char;
    }
  }

  return prefix === '' ? word : `${prefix}-${word}`;
}

export function nameFallback(name: string | undefined) {
  if (!name || name.trim() === '') {
    return '(ditt navn)';
  }

  return name.trim();
}

/**
 * Add "AS" if necessary. Replace "A/S" with "AS". Remove redundant whitespace.
 */
export function normalizeCompanyName(name: string) {
  name = name
    .trim()
    .replace(/ (as|a\/s)$/i, ' AS')
    .replace(/\s+/g, ' ');
  const words = name.toLowerCase().split(' ');
  // If the word "AS" or "aksjeselskap" is included, don't automatically append
  // AS. Validation will stop it later so that the user can manually fix it.
  const nameHasAS = words.includes('as') || words.includes('aksjeselskap');
  return nameHasAS ? name : `${name} AS`;
}

// Calculate actual company name length whitout whitespace, endings, etc.
// Names must have three letters from the Norwegian alphabet.
// https://lovdata.no/NL/lov/1985-06-21-79/§2-1
// Note that this doesn't count characters correctly, see
// https://mathiasbynens.be/notes/javascript-unicode
export function getCompanyNameLength(name: string) {
  return name
    .replace(/ (as|aksjeselskap)$/i, '')
    .replace(/[^A-ZÆØÅÀÁÂÈÉÊÌÍÎÒÓÔÙÚÛÝÄËÏÖÜŸ]/gi, '')
    .trim().length;
}

function getBankOrigin(): string {
  // TODO: This should use config from somewhere.
  const origin = window.location.origin;
  return origin.startsWith('https://dev.')
    ? 'https://dev.app.folio.no'
    : 'https://app.folio.no';
}

export function getOnboardingUrl(
  id: string,
  bankSelection: MaBankSelection | null,
) {
  if (
    bankSelection === 'FOLIO' ||
    bankSelection === 'FOLIO_AML_COMPLETE' ||
    bankSelection === 'FOLIO_ONBOARDED'
  ) {
    const url = new URL('/stiftelse/fortsett', getBankOrigin());
    url.searchParams.set('id', id);
    return url.href;
  } else {
    const url = new URL('/stiftelse', getBankOrigin());
    url.searchParams.set('id', id);
    return url.href;
  }
}

export function getBankUrl(orgNumber: string) {
  return new URL(`/org/${orgNumber}`, getBankOrigin()).href;
}

// https://github.com/folio-as/monorepo/issues/4395#issuecomment-857646569
const allowedOwnerOrgTypes = new Set([
  'ANNA',
  'ANS',
  'AS',
  'ASA',
  'BA',
  'BBL',
  'BO',
  'BRL',
  'DA',
  'FLI',
  'FYLK',
  'GFS',
  'IKS',
  'KBO',
  'KIRK',
  'KOMM',
  'KS',
  'PK',
  'PRE',
  'SA',
  'SF',
  'SPA',
  'STAT',
  'STI',
  'SÆR',
  'VPFO',
]);

export function isAllowedOwnerOrgType(orgType: string) {
  return allowedOwnerOrgTypes.has(orgType);
}

/**
 * Guess if this founding was created on the folio stiftemaskinen or on a
 * partner version of stiftemaskinen.
 */
export function getSystemId(
  url: URL,
): 'folio' | 'fiken' | 'tripletex' | 'ecit' {
  if (url.searchParams.get('kilde') === 'ecit') {
    return 'ecit';
  }

  if (url.hostname === 'fiken.no' || url.hostname.endsWith('.fiken.no')) {
    return 'fiken';
  }

  if (
    url.hostname === 'tripletex.no' ||
    url.hostname.endsWith('.tripletex.no')
  ) {
    return 'tripletex';
  }

  return 'folio';
}

/**
 * Add a prefix to id's for external partners to be able to differentiate them
 */
export function makeIdWithPrefix() {
  const systemId = getSystemId(new URL(window.location.href));

  switch (systemId) {
    case 'fiken':
      return makeId('f');

    case 'tripletex':
      return makeId('t');

    case 'ecit':
      return makeId('ed');

    default:
      return makeId('b'); // FIXME: post-dse: prefix can be removed after a while
  }
}

export function removeTrailingDot(text: string): string {
  return text.replace(/[.]+$/g, '');
}

const INVESTING_COMPANY_ACTIVITY_ID = '2830';
const HOLDING_COMPANY_ACTIVITY_ID = '2835';
const SHELF_COMPANY_ACTIVITY_ID = '2832';

const investingCompanyActivityIds = [
  INVESTING_COMPANY_ACTIVITY_ID,
  HOLDING_COMPANY_ACTIVITY_ID,
];

export function isInvestingOrShelfCompany(activityId?: string) {
  if (!activityId) {
    return false;
  }

  return (
    investingCompanyActivityIds.includes(activityId) ||
    activityId === SHELF_COMPANY_ACTIVITY_ID
  );
}

function isInvestingCompany(activityId?: string) {
  if (!activityId) {
    return false;
  }

  return investingCompanyActivityIds.includes(activityId);
}

export function getCompanyType(activityId?: string): CompanyType {
  return isInvestingCompany(activityId) ? 'INVESTING' : 'OTHER';
}

export function allSigned(signerUrls: readonly MaSignerURL[]) {
  return signerUrls.every(signerUrl => signerUrl.signed);
}

export const isProbablyMobile = navigator.userAgent
  .toLowerCase()
  .includes('mobi');
